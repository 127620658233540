/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, ReactElement } from 'react'
import { Users as UsersFromStore } from '../../../../../../app/slices/Users'
import { useAppSelector } from '../../../../../../app/hooks'
import './style.css'  // Ensure the path is correct relative to the component file

type Props = {
	refs: MutableRefObject<HTMLDivElement | null>
	recieptData?: any
}

const plans = [
	{
		label: 'Personal Quarterly Subscription',
		price: 37.00,
		planId: 'personal-3-month',
	},
	{
		label: 'Personal Annual Subscription',
		price: 129.00,
		planId: 'personal-1-year',
	},
	{
		label: 'Personal Monthly Subscription',
		price: 15.00,
		planId: 'personal-monthly',
	},
	{
		label: 'Commercial Annual Subscription',
		price: 249.00,
		planId: 'commercial-1-year',
	}
]

const formattedDate = (transactionDate: string): string => {
	const date = new Date(transactionDate)

	// Adjust to a specific timezone (in this case, GMT+8)
	const options: Intl.DateTimeFormatOptions = {
		weekday: 'short', // 'Mon'
		year: 'numeric', // '2024'
		month: 'short', // 'Dec'
		day: '2-digit', // '01'
		hour: '2-digit', // '04'
		minute: '2-digit', // '00'
		hour12: true, // 'AM/PM'
		timeZoneName: 'short', // 'GMT+8'
		timeZone: 'Asia/Singapore', // Adjust to GMT+8
	}
	const formattedDate = date.toLocaleString('en-US', options)
	return formattedDate.replace(/(AM|PM)/, ' $1').replace(' GMT+8', ' GMT+8')
}

function PDFReceipt({ refs, recieptData }: Props): ReactElement {
	const { userData } = useAppSelector(UsersFromStore)
	const planId = userData?.subscription?.planId
	const isCommercial = userData?.subscription?.planId?.includes('commercial')
	const isAddons = recieptData?.data?.braintree?.transaction_type == 'additional-user'

	const matchedPlan = plans.find(plan => plan.planId === planId)
	const planLabel = matchedPlan ? matchedPlan.label : 'Unknown Plan'
	const planPrice = matchedPlan ? matchedPlan.price : 0

	if (!recieptData || !recieptData?.data?.user) {
		return <div>Loading...</div>
	}

	return (
		<div ref={refs} className='reciept-layout'>
			<div className='receipt-logo'>
				<img className='reciept-img-header' src='img/logo-mark-color.png' alt='logo' />
			</div>
			{/* <div className='text-style'>
				Dear {recieptData?.data?.user?.first_name} {recieptData?.data?.user?.last_name},
			</div>
			<div className="intro text-style">
				Thank you for subscribing to Robert Parker Wine Advocate. Please see your payment receipt below. If you wish to download a copy of the receipt, please check your <span style={{ textDecoration: 'underline' }}>Payment History</span> to download a PDF version.
			</div>

			<div className='intro text-style'>Robert Parker Wine Advocate Support</div>
			<div className='separator'></div> */}
			<div className='header'>Payment Receipt</div>
			<div className='payment-receipt'>
				<div className='payment-content'>
					<div className='field text-bold'>Customer Email</div>
					<div className='field'>{recieptData?.data?.user?.email}</div>
					<div className='field text-bold'>Transaction ID</div>
					<div className='field'>{recieptData?.data?.braintree?.transaction_id}</div>
					<div className='field text-bold'>Transaction Date</div>
					<div className='field'>{formattedDate(recieptData?.data?.transaction_date)}</div>
				</div>
				<div className='payment-content'>
					<div className='field text-bold'>Billed To:</div>
					<div className='field'>{recieptData?.data.credit_card.card_type} {recieptData?.data?.credit_card?.number}</div>
					<div className='field'>{recieptData?.data?.credit_card?.name}</div>
					<div className='field'>{recieptData?.data?.credit_card?.postal_code}</div>
					<div className='field'>{recieptData?.data?.credit_card?.country_name}</div>
					{isCommercial &&
						<>
							<div className='field text-bold'>Company Details</div>
							<div className='field'>{recieptData?.data?.user_company?.name}</div>
							<div className='field'>{recieptData?.data?.user_company?.address?.street}</div>
							<div className='field'>{recieptData?.data?.user_company?.address?.city}</div>
							<div className='field'>{recieptData?.data?.user_company?.address?.state} {recieptData?.data?.user_company?.address?.zip}</div>

							{recieptData?.data?.payment_amount?.tax_value !== null &&
								<>
									<div className='field text-bold'>VAT No.</div>
									<div className='field'>{recieptData?.data?.user_company?.vat_number}</div>
								</>
							}
						</>
					}
				</div>
			</div>
			<div className='header'>Order Review</div>
			<div className='light-separator'></div>
			{!isAddons &&
				<div className='breakdown'>
					<div>{planLabel} (Auto-Renew)</div>
					<div>${planPrice.toFixed(2)}</div>
				</div>
			}

			{isCommercial && recieptData?.data?.addOns?.length > 0 && (
				<div className='breakdown margin-top-6px'>
					<div>Add-ons x{recieptData?.data?.addOns[0]?.quantity}</div>
					<div>{(parseFloat(recieptData?.data?.addOns[0]?.amount) || 0).toFixed(2)}</div>
				</div>
			)}

			{recieptData?.data?.promo?.code &&
				<div className='breakdown margin-top-6px'>
					<div>Promo Code ({recieptData?.data?.promo?.code})</div>
					<div>- ${(recieptData?.data?.promo?.amount || 0).toFixed(2)}</div>
				</div>
			}

			{recieptData?.data?.payment_amount?.tax_value !== null &&
				<div className='tax-info margin-top-15px'>
					Inclusive of tax at <b>{recieptData?.data?.payment_amount?.tax_value || 0}%</b>
					<span className='tabbed'>${(recieptData?.data?.payment_amount?.tax_amount || 0).toFixed(2)}</span>
				</div>
			}

			<div className='tax-info margin-top-30px'>
				Subtotal
				<span className='tabbed'>${(recieptData?.data?.payment_amount?.sub_total || 0).toFixed(2)}</span>
			</div>

			{recieptData?.data?.payment_amount?.tax_value !== null &&
				<div className='tax-info margin-top-20px'>
					Amount subject to tax <b>{recieptData?.data?.payment_amount?.tax_value || 0}%</b>
					<span className='tabbed'>${(recieptData?.data?.payment_amount?.tax_amount || 0).toFixed(2)}</span>
				</div>
			}

			<div className='theme-separator'></div>
			<div className='breakdown'>
				<div className='total'>Total</div>
				<div className='total'>${(recieptData?.data?.payment_amount?.amount || 0).toFixed(2)}</div>
			</div>
			<div className='theme-separator'></div>
			<div className='outro'>
				As highlighted during the subscription process, you have chosen to access digital content provided without physical support. As execution began before the end of the withdrawal period, you acknowledged and agreed to waive your right of withdrawal.
			</div>
			<div className='centered-info'>
				<div className='text-bold'>Robert Parker Wine Advocate</div>
				<div>6th West 18th Street, 5th Floor, New York, NY 10011, USA</div>
				<div>+1-707-255-5337 | support@robertparker.com</div>
			</div>
		</div>
	)
}

export default PDFReceipt
