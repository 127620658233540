import React, { ReactElement, useEffect, useState } from 'react'

// Page Content
import PaymentHistory from './PaymentHistory'
import PaymentMethods from './PaymentMethods'
import SubscriptionDetail from './SubscriptionDetail'
import SubscriptionHistory from './SubscriptionHistory'

import { IsUserAuthenticated } from '../../../../utils'
import { useHistory, useLocation } from 'react-router-dom'
import { Notification, SubscriptionHeader } from '../subscriptions/styles'
import MenuSelections from '../../../../components/menu-selections/MenuSelections'
import { isMobile } from 'react-device-detect'

const menuOptions = [
	{ label: 'Subscription Details', component: <SubscriptionDetail /> },
	{ label: 'Subscription History', component: <SubscriptionHistory /> },
	{ label: 'Payment History', component: <PaymentHistory /> },
	{ label: 'Manage Payment Methods', component: <PaymentMethods /> },
]

interface LocationState {
	isPaymentRoute?: boolean
	action?: string
	isExpired: boolean
}

function MySubscription(): ReactElement {
	const location = useLocation<LocationState>()
	const { isPaymentRoute, action, isExpired } = location.state || {}

	const savedTab = localStorage.getItem('activeMenu')
	const initialTab = savedTab ? parseInt(savedTab, 10) : isPaymentRoute ? 3 : 0

	const [activeMenu, setActiveMenu] = useState(initialTab)
	const [paymentRoute, setPaymentRoute] = useState<boolean | undefined>(
		isPaymentRoute
	)
	const history = useHistory()
	const [counter, setCounter] = useState(5)

	useEffect(() => {
		if (action && counter > 0) {
			const timer = setTimeout(() => setCounter((prev) => prev - 1), 1000) 
			return () => clearTimeout(timer) 
		}

		if (counter === 0 && action) {
			history.replace({
				...location,
				state: {
					...location.state,
					action: undefined,
				},
			})
		}
	}, [counter, action, history, location])

	useEffect(() => {
		if (!IsUserAuthenticated()) {
			history.push('/login')
		}
	}, [history])

	useEffect(() => {
		if (!isPaymentRoute) {
			setActiveMenu(0)
		}
	}, [isPaymentRoute])

	const onMenuChangeHandler = (index: number) => {
		setActiveMenu(index)
		setPaymentRoute(undefined)
		localStorage.setItem('activeMenu', index.toString())
	}

	return (
		<div className="single-entry">
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SubscriptionHeader>My Subscription</SubscriptionHeader>
					<MenuSelections
						className="sub-selection"
						selectedMenu={activeMenu}
						menuItems={menuOptions.map((menu) => menu.label)}
						onMenuChange={onMenuChangeHandler}
					/>
					{action && (
						<Notification isMobile={isMobile}>
							<div className="message">
								{action === 'add'
									? 'Card has been added successfully'
									: 'Card Details have been updated successfully'}
							</div>
						</Notification>
					)}
					<article>{menuOptions[activeMenu].component}</article>
				</div>
			</div>
		</div>
	)
}

export default MySubscription
