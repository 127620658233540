/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'
import { sprintf } from 'sprintf-js'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'
import { GetBaseQuery } from '../../utils'

type PaymentPayload = {
	name: string;
	number: string;
	cvv: string;
	expirationDate: string;
	postalCode: string;
	customerId: string;
}

type UpdatePaymentPayload = {
	name: string;
	number: string;
	cvv: string;
	expirationDate: string;
	postalCode: string;
	customerId: string;
	token: string;
}

type DeletePaymentMethod = {
	paymentMethodToken: string
}

export const PaymentMethodAPI = createApi({
	reducerPath: 'PaymentMethodAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (build) => ({
		createPaymentMethod: build.mutation({
			query: ({params, token}: { params: PaymentPayload, token: string }) => ({
				url: API_ENDPOINTS.create_payment_method_braintree,
				method: 'POST',
				body: params,
				headers: {
					'XSRF-TOKEN': token
				},
				credentials: 'include',
				mode: 'cors'
			}),
		}),
		findPaymentMethod: build.query<any, string>({
			query: (parameters: string) =>
				sprintf(API_ENDPOINTS.get_specific_payment, parameters),
		}),

		getPaymentReceipt: build.mutation({
			query: ({ params, token }: { params: string; token: string }) => ({
				url: `${API_ENDPOINTS.get_payment_receipt}?transactionId=${params}`,
				method: 'GET',
				headers: {
					'XSRF-TOKEN': token,
				},
				credentials: 'include',
				mode: 'cors'
			}),
		}),

		updatePaymentMethod: build.mutation({
			query: ({ params, token }: { params: UpdatePaymentPayload, token: string }) => {
				return {
					url: API_ENDPOINTS.update_payment_method,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		deletePaymentMethodBraintree: build.mutation({
			query: (params: DeletePaymentMethod) => {
				return {
					url: API_ENDPOINTS.delete_payment_method_braintree,
					method: 'POST',
					body: params,
				}
			}
		}),
		setDefaultPaymentMethod: build.mutation({
			query: (params: DeletePaymentMethod) => {
				return {
					url: API_ENDPOINTS.set_default_payment_method,
					method: 'POST',
					body: params,
				}
			}
		}),
	}),

})

export const {
	useCreatePaymentMethodMutation,
	useFindPaymentMethodQuery,
	useGetPaymentReceiptMutation,
	useUpdatePaymentMethodMutation,
	useDeletePaymentMethodBraintreeMutation,
	useSetDefaultPaymentMethodMutation
} = PaymentMethodAPI