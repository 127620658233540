/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react'
import moment from 'moment'
import { useHistory, useLocation, useParams } from 'react-router-dom'
// import TagManager from 'react-gtm-module'

import { useAppSelector } from '../../app/hooks'
import { Users as UsersFromStore } from '../../app/slices/Users'
import { IsUserAuthenticated } from '../../utils'
import { Seperator } from '../../views/content/pages/subscriptions/styles'
import RegionCard, { LinkButton } from './RegionCard'

import { SuccessContainer } from './styles'
import { PLAN_ANALYTICS, TASTING_NOTES_LIST } from '../../configs'
import {Analytics as AnalyticsFromStore} from '../../app/slices/Analytics'
import { reverseObfuscateConstant } from '../../utils/Constant'

interface SuccessActivationType {
	header: string;
	bodyOne: string;
	bodyTwo?: string;
}

const regionList = [{
	key: 'bordeaux',
	name: 'Bordeaux',
	url: '/search/wine?country=France&region=Bordeaux',
	imgUrl: '/img/icons/icon_bordeaux.svg'
}, {
	key: 'burgundy',
	name: 'Burgundy',
	url: '/search/wine?country=France&region=Burgundy',
	imgUrl: '/img/icons/icon_burgundy.svg'
}, {
	key: 'california',
	name: 'California',
	url: '/search/wine?country=USA&region=California',
	imgUrl: '/img/icons/icon_california.svg'
}, {
	key: 'champagne',
	name: 'Champagne',
	url: '/search/wine?country=France&region=Champagne',
	imgUrl: '/img/icons/icon_champagne.svg'
}, {
	key: 'italy',
	name: 'Italy',
	url: '/search/wine?country=Italy',
	imgUrl: '/img/icons/icon_italy.svg'
}]

function SuccessActivation({header, bodyOne, bodyTwo}: SuccessActivationType): ReactElement {
	
	const { userData } = useAppSelector(UsersFromStore)
	const { search } = useLocation()
	const history = useHistory()
	const {type} = useParams<{ type: string }>()
	// const queryParams = new URLSearchParams(search)
	let planType = 'none'

	if(search === '?type=personal')
		planType = 'personal'

	if(search === '?type=commercial')
		planType = 'commercial'

	const { checkout } = useAppSelector(AnalyticsFromStore)

	useEffect(() => {
		if(!IsUserAuthenticated()){
			history.push('/login')
		}
	}, [])

	useEffect(() => {
		if(checkout)
			if(type === 'success' && checkout.planId && (planType === 'commercial' || planType === 'personal')){
				triggerSucessAnalytics()
			}
	}, [type, checkout])

	const triggerSucessAnalytics = () => {
		const selectedPlan = PLAN_ANALYTICS.find(plan => plan.planId === checkout.planId)

		if(selectedPlan){
			const tastingNote = TASTING_NOTES_LIST.find(tasting_note => tasting_note.type === checkout.tasting_notes)
			const total = selectedPlan.originalPrice + (checkout.seats * 129) - checkout.discountValue + (tastingNote?.cost ?? 0)
			const couponCode = checkout.discountCoupon ? reverseObfuscateConstant(checkout.discountCoupon) : undefined
			
			const event = {
				event: 'purchase',
				ecommerce: {
					currency: 'USD',
					transaction_id: checkout.transactionId,
					value: total,
					items: [
						{
							recommended_plan: selectedPlan.recommended,
							item_list_id: selectedPlan.item_list_id,
							item_list_name: selectedPlan.item_list_name,
							item_brand: selectedPlan.item_brand,
							item_name: selectedPlan.item_name,
							item_category: selectedPlan.item_category,
							item_category2: selectedPlan.item_category2,
							coupon: couponCode,
							discount: checkout.discountValue ?? 0,
							additionnal_seats: selectedPlan.item_category === 'Commercial' ? checkout.seats: undefined,
							additionnal_tasting_notes: selectedPlan.item_category === 'Commercial' ? checkout.tasting_notes ? true : false : undefined,
							price: total
						}
					]
				}
			}

			// console.log(`event: ${type}`, event)

			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				ecommerce: null
			})
			window.dataLayer.push(event)

			// TagManager.dataLayer({
			// dataLayer: {
			// 	ecommerce: null
			// }
			// })

			// TagManager.dataLayer({
			// 	dataLayer: event
			// })
		}
	}

	return <div>
		<SuccessContainer className='container'>
			<div className='head-content'>
				<img alt='icon-success' src='/img/icons/icon_success.svg'  />
				<div className='head-content-body'>
					<h3>{header}</h3>
					<p>{bodyOne} {moment(userData?.subscription?.end).format('MMMM DD, YYYY')}.</p>
					{bodyTwo && <p>{bodyTwo}</p>}
					<LinkButton href='/my-subscription'>
							MANAGE YOUR ACCOUNT
					</LinkButton>
				</div>
			</div>
			<Seperator width='100%' />
			<div className='regions-selection'>
				<h3>Browse Wines from Notable Regions</h3>
				<div className='regions'>
					{regionList.map(region => <RegionCard key={region.key} name={region.name} url={region.url} imgUrl={region.imgUrl} />)}
				</div>
			</div>
		</SuccessContainer>
	</div>
}

export default SuccessActivation
