/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from 'react'
import moment from 'moment'

import { useAppSelector } from '../../../../app/hooks'

import { useGetSubscriptionHistoryQuery } from '../../../../app/services'

import { APP_CONFIG, PLANS } from '../../../../configs'

import LoadingOverlay from '../../../../components/Loading-overlay'

// Styles
import { NoSubscriptionLabel, Row, SubsHistoryDateLabel, SubsHistoryPaymentLabel, SubsHistoryPlanLabel, SubsHistoryStatusLabel, SubscriptionHistoryContainer } from './styles'
import  './style.css'
import {Users as UsersFromStore} from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'


interface ISubscriptionHistoryState {
	list: any
	toggle: any
	noSubcriptionsHistory: boolean
}

function SubscriptionHistory(): ReactElement {
	const [subscriptionHistoryState, setsubscriptionHistoryState] = useState<ISubscriptionHistoryState>({
		list: [],
		toggle: {},
		noSubcriptionsHistory: true,
	})
	const { userData } = useAppSelector(UsersFromStore)

	const { data, isError } = useGetSubscriptionHistoryQuery(userData?._id, { skip: !userData?._id })
	const [state, setState] = useState({
		isLoading: false,
	})

	useEffect(() => {
		setState({ isLoading: true })
		if(data){
			if (data.success) {
				if(data.data.length > 0){
					setsubscriptionHistoryState(state => ({ ...state, list: data.data, noSubcriptionsHistory: false }))
				}
				setState({ isLoading: false })
			}
		}
		
		if(isError){
			setState({ isLoading: false })
			console.log('Something went wrong. Please try again.')
		}
	}, [data, isError])

	return (
		<div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'column', alignItems: 'center'}}>
			{state.isLoading && (<LoadingOverlay />)}
			{subscriptionHistoryState.list && subscriptionHistoryState.list.length > 0 ?
				subscriptionHistoryState.list.map((history: any, producer_result_key: number) => (
					<div className="sub-history" key={producer_result_key}>
						<SubscriptionHistoryContainer>
							<Row>
								<SubsHistoryPlanLabel>
									<div className='title'>{PLANS.map(plan => plan.planId === history.planId ? plan.label : '')}</div>
								</SubsHistoryPlanLabel>
								<SubsHistoryStatusLabel>
									<div className='status'>{history.status}</div>
								</SubsHistoryStatusLabel>
							</Row>
							<Row>
								<SubsHistoryDateLabel>
									<div className=''>
										<span>{moment(history.start).format(APP_CONFIG.DEFAULTS.DATE_FORMAT)}</span>
										<span> - </span>
										<span>{moment(history.end).format(APP_CONFIG.DEFAULTS.DATE_FORMAT)}</span>
									</div>
								</SubsHistoryDateLabel>
							</Row>
							<SubsHistoryPaymentLabel>
								<span className='payment-detal-label'>Subscription ID: &nbsp;</span>
								<span className='payment-detail-value'>{history?._id || '(Not Set)'}</span>
							</SubsHistoryPaymentLabel>
						</SubscriptionHistoryContainer>
						{/* <Row style={{ paddingTop: '15px' }}>
									<SeparatorContainer>
										<SectionSeparator position={'horizontal'}></SectionSeparator>
									</SeparatorContainer>
								</Row> */}
					</div>
				)
				) :
				subscriptionHistoryState.noSubcriptionsHistory &&
				(<NoSubscriptionLabel>No subscription history available</NoSubscriptionLabel>)}
		</div>
	)
}

export default SubscriptionHistory