import React, { MutableRefObject } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import { generateMonthOptions, generateYearOptions } from '../../gift-subscription/gift-sub/components/constants'
import Input from '../../../../../components/form-components/input/Input'
import Dropdown from '../../../../../components/form-components/dropdown/Dropdown'
import { isMobile } from 'react-device-detect'
import { ModalTitle, SecurePaymentText } from '../styles'
import CreditCards from './CreditCards'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'
import { RP_ENV } from '../../../../../configs'

interface AddUpdateForm {
	title: string;
	control: Control<FieldValues>;
	onRecaptchaTokenGenerated: (token: string | null) => void;
	recaptchaRef: MutableRefObject<null>;
}

const AddUpdateForm = ({ title, control, onRecaptchaTokenGenerated, recaptchaRef }: AddUpdateForm) => {
	const months = generateMonthOptions()
	const years = generateYearOptions()

	const recaptchaHandler = (token: string | null) => {
		onRecaptchaTokenGenerated(token)
	}

	const inputStyles = {
		web: {
			display: 'flex',
			flexDirection: 'row',
			gap: '1rem',
			width: '100%',
			justifyContent: 'space-between',
		} as const,
		input: {
			flex: 1,
			width: '100%',
		},
	}

	return (
		<div className="form" style={{overflowY: 'scroll', scrollbarWidth: 'none', height: isMobile ? 'auto' : 'auto', zIndex: 10, marginTop: 12, marginBottom: 0}}>
			<ModalTitle title={title} isMobile={isMobile}>{title}</ModalTitle>
			<CreditCards />
		
			<div style={isMobile ? {} : inputStyles.web}>
				<Input control={control} name="cardHolderFirstName" type="text" placeholder="Cardholder First Name" rules={{ required: 'Cardholder first name is required' }} />
				<Input control={control} name="cardHolderLastName" type="text" placeholder="Cardholder Last Name" rules={{ required: 'Cardholder last name is required' }} />
			</div>
			
			<div className={isMobile ? '' : 'first-input'}>
				<Input control={control} name="cardNumber" type="text" placeholder="Credit card number" rules={{ required: 'Credit card number is required' }} />
			</div>
			<div style={isMobile ? {} : inputStyles.web}>
				<Dropdown control={control} options={months} placeholder="Exp. Month" name="expiryMonth" rules={{ required: 'Expiration month is required' }} />
				<Dropdown control={control} options={years} placeholder="Exp. Year" name="expiryYear" rules={{ required: 'Expiration year is required' }} />
			</div>
			<div style={isMobile ? {} : inputStyles.web}>
				<Input control={control} name="cvv" type="text" placeholder="CVV" rules={{ required: 'CVV is required' }} />
				<Input control={control} name="zipCode" type="text" placeholder="ZIP / Postal Code" rules={{ required: 'Zip / Postal code is required' }} />
			</div>
			<div
				style={{
					marginBottom: isMobile ? '' : '2rem',
					alignSelf: 'center',
					display: 'flex',         
					flexDirection: 'column', 
					justifyContent: 'center',
					alignItems: 'center',    
					textAlign: 'center'  
				}}
			>
				<i>Please check the box below to proceed.</i>
				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
					onChange={recaptchaHandler}
				/>
			</div>

			<SecurePaymentText isMobile={isMobile}>All payments are secured and encrypted.</SecurePaymentText>
		</div>
	)
}

export default AddUpdateForm