import styled from 'styled-components'

export const PageMainContainer = styled.div<{ isUpdate: boolean, isMobile: boolean }>`
	display: flex;
	align-items: center;
	flex-direction: column;

    & .header-container {
        display: flex;
        align-items: center;
    }

    & .breadcrumb {
        width: 210px;
        background-color: #fff;
        margin-bottom: 0;
        font-family: Libre Franklin;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #311820;
        text-decoration: underline;
        cursor: pointer;
    }

    & .header {
        font-family: Libre Franklin;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        margin-top: 0px;
        margin-left: 220px;
        margin-right: 220px;
        width: ${(props) => (props.isMobile ? '100%' : '')};
    }

    & .payment-card {
        border-radius: 12px;
        border: solid 1px #a18436;
        width: 564px;
        height: 736px;
        padding: 32px;
        margin-top: 32px;
    }

    & .custom-button {
        display: flex;
        justify-content: center;
        gap: 16px;
    }

    & .cancel-button {
        width: 120px;
        height: 49px;
        padding: 14px 30px;
        border-radius: 4px;
        border: solid 1px #73333f;
        cursor: pointer;
    }

    & .add-button {
        width: 143px;
        height: 49px;
        padding: ${(props) => (props.isUpdate ? '14px 42px' : '14px 56px')};
        border-radius: 4px !important;
        background-color: #73333f;
        color: white;
        border: none;
    }

    @media (max-width: 991px) {
        .header {
            margin-top: 32px;
        }

        .payment-card {
            height: 100%;
        }
    }

    @media (max-width: 564px) {
        .payment-card {
            width: 100%;
            border-radius: 0;
        }
    }
`