/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'

// Services
import { useDeletePaymentMethodBraintreeMutation, useGetCustomerPaymentMethodQuery } from '../../../../app/services'

// Slices
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'
import LoadingOverlay from '../../../../components/Loading-overlay'
import ErrorModal from '../../../../components/error-modal'
import DeletePaymentMethodModal from './modals/delete-payment-method'

// Styles
import {
	AddPaymentButton, ClickableDiv, DeleteCard, InnerButton, NoSubscriptionLabel,
	PaymentItemContainer, PaymentMethodActionContainer, PaymentMethodCardContainer,
	PaymentMethodCardInfo, PaymentMethodCardLogo, PaymentMethodCardTitle,
	PaymentMethodCardUser, PaymentMethodContainer, PaymentMethodEmptyContainer,
	PaymentMethodRow, SubscriptionSeparator, UpdateButton
} from './styles'

import './style.css'
import CommonModalContainer from '../../../modals/ModalContainer'
import { FormNotifications, SuccessNotification } from '../my-profile/styles'
import NoticeModal from './modals/add-ons'
import { useHistory, useLocation } from 'react-router-dom'

interface IPaymentMethodState {
	list: any
	toggle: any
	noPaymentMethods: boolean
}

type SelectedDeletedMethodType = {
	ending: string;
	token: string;
	defaultStatus: boolean;
}

interface LocationState {
	isPaymentRoute?: boolean
	action?: string
	isExpired: boolean
}

function PaymentMethods(): ReactElement {
	const { userData } = useAppSelector(UsersFromStore)
	const customerId = userData?._id
	const { data: paymentData, isLoading, refetch: allPaymentsRefetch, isError: paymentMethodQueryIsError } = useGetCustomerPaymentMethodQuery(customerId, { skip: !customerId })
	const [deletePayment, { data: deletePaymentData, isLoading: deleteLoading, isError: deletePaymentIsError }] = useDeletePaymentMethodBraintreeMutation()

	const history = useHistory()

	const [showNotification, setShowNotification] = useState<{ message: string, show: boolean }>({
		message: '',
		show: false
	})
	const [selectToDeleteMethod, setSelectToDeleteMethod] = useState<SelectedDeletedMethodType>({
		ending: '',
		token: '',
		defaultStatus: false,
	})
	const [paymentMethodState, setPaymentMethodState] = useState<IPaymentMethodState>({
		list: [],
		toggle: {},
		noPaymentMethods: true
	})
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [isDeleteSuccess, setIsDeleteSuccess] = useState(false)
	const [seconds, setSeconds] = useState(3)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any[]>([])
	const [toggleExpireModal, setToggleExpireModal] = useState(false)

	const location = useLocation<LocationState>()
	const { isPaymentRoute, action, isExpired } = location.state || {}

	useEffect(() => {
		if (paymentData) {
			allPaymentsRefetch()
			if (paymentData.success) {
				setPaymentMethodState(state => ({ ...state, list: paymentData.data, noPaymentMethods: false }))
			}
		}

		if (paymentMethodQueryIsError) {
			setShowNotification({
				message: 'Something went wrong. Please try again.',
				show: true,
			})
		}
	}, [paymentData, paymentMethodQueryIsError])

	useEffect(() => {
		if (isExpired) {
			setToggleExpireModal(true)
		}
	}, [isExpired])

	const selectedToDeletHandler = (elements: any) => {
		if (selectedPaymentMethod.length != 0) {
			setSelectToDeleteMethod({
				ending: elements.last4,
				token: elements.token,
				defaultStatus: elements.default
			})
			setIsDeleteModalOpen(true)
		} else {
			setShowNotification({
				show: true,
				message: 'Please select a payment method to delete.',
			})
		}
	}

	const deleteRequestHandler = async () => {
		try {
			await deletePayment({
				paymentMethodToken: selectToDeleteMethod.token
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (deletePaymentData) {
			if (deletePaymentData.success) {
				allPaymentsRefetch()
				setIsDeleteSuccess(true)
			} else {
				setShowNotification({
					message: deletePaymentData.message,
					show: true,
				})
			}
		}

		if (deletePaymentIsError) {
			setShowNotification({
				message: 'Something went wrong. Please try again.',
				show: true,
			})
		}
	}, [deletePaymentData, deletePaymentIsError])

	useEffect(() => {
		if (isDeleteSuccess && seconds > 0) {
			const timer = setTimeout(() => {
				setSeconds(prevSeconds => prevSeconds - 1)
			}, 1000)

			return () => clearTimeout(timer)
		} else if (seconds === 0) {
			window.location.reload()
		}
	}, [isDeleteSuccess, seconds])


	const deleteHandler = async () => {
		if (paymentMethodState.list.length > 1) {
			if (selectToDeleteMethod.defaultStatus) {
				// setIsErrorModalOpen(true)
				setShowNotification({
					show: true,
					message: 'It is not possible to remove the default payment method.',
				})
				return
			} else {
				await deleteRequestHandler()
			}
		} else {
			await deleteRequestHandler()
		}
	}

	// const handleSetDefault = async (data: any) => {

	// 	const response = await defaultPayment({
	// 		paymentMethodToken: data.token
	// 	})
	// 	if ('data' in response) {
	// 		if ('success' in response.data) {
	// 			const data = (response as { data: any }).data
	// 			if (data.success) {
	// 				allPaymentsRefetch()
	// 			} else {
	// 				setShowNotification({0
	// 					message: data.message,
	// 					show: true,
	// 				})
	// 			}

	// 		}
	// 	}
	// }

	const closeModal = () => {
		setShowNotification({
			message: '',
			show: false
		})
	}

	const modalHandler = (value: boolean) => {
		setIsDeleteModalOpen(value)
	}

	const resetIsExpired = () => {
		history.replace({
			...location,
			state: {
				...location.state,
				isExpired: undefined,
				isPaymentRoute: undefined,
				action: undefined
			},
		})
	}

	const handleExpireModal = (result: boolean) => {
		setToggleExpireModal(false)
		if (result) {
			history.push('/subscriptions')
		} else {
			resetIsExpired()
		}
	}

	const handleSelectPaymentMethod = (ele: string) => {
		const newSelectedPaymentMethod = selectedPaymentMethod.includes(ele)
			? selectedPaymentMethod.filter(item => item !== ele)
			: [...selectedPaymentMethod, ele]
		setSelectedPaymentMethod(newSelectedPaymentMethod)
	}

	const handleUpdate = () => {
		if (selectedPaymentMethod.length > 0) {
			history.push('/my-subscription/edit-payment-method')
		} else {
			setShowNotification({
				show: true,
				message: 'Please select a payment method to update.',
			})
		}
	}

	return (
		<div>
			{paymentMethodState.list && paymentMethodState.list?.length > 0 ?
				<PaymentMethodContainer className={isMobile ? 'mobile' : 'desktop'}>
					{paymentMethodState.list.map((ele: any, index: any) => {
						return (
							<PaymentItemContainer key={index}>
								<PaymentMethodCardContainer isMobile={isMobile}>
									{isMobile && <SubscriptionSeparator />}
									<PaymentMethodCardInfo>
										<PaymentMethodCardTitle>Payment Methods</PaymentMethodCardTitle>
										<PaymentMethodRow style={{}}>
											<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
												<PaymentMethodCardLogo>
													<img src={ele.imageUrl} />
												</PaymentMethodCardLogo>
												<div className='payment-details'>
													<PaymentMethodCardUser>
														<span>{ele.cardType} ending in {ele.last4}</span>
													</PaymentMethodCardUser>
												</div>
											</div>
											<ClickableDiv onClick={() => handleSelectPaymentMethod(ele)}>
												<InnerButton isClicked={selectedPaymentMethod.includes(ele)} />
											</ClickableDiv>
										</PaymentMethodRow>
										<PaymentMethodActionContainer>
											<DeleteCard onClick={() => selectedToDeletHandler(ele)}>DELETE CARD</DeleteCard>
											<UpdateButton onClick={handleUpdate}>UPDATE</UpdateButton>
										</PaymentMethodActionContainer>
									</PaymentMethodCardInfo>
									{isMobile && <SubscriptionSeparator />}
								</PaymentMethodCardContainer>
							</PaymentItemContainer>
						)
					})}
				</PaymentMethodContainer>
				: !paymentMethodState.noPaymentMethods || paymentMethodState.noPaymentMethods ?
					<div style={{ display: isMobile ? '' : 'flex', justifyContent: isMobile ? '' : 'center', alignItems: isMobile ? '' : 'center' }}>
						{isMobile && <SubscriptionSeparator />}
						<PaymentMethodEmptyContainer isMobile={isMobile}>
							<div>
								<PaymentMethodCardTitle>Payment Methods</PaymentMethodCardTitle>
								<NoSubscriptionLabel>You have not added any payment methods</NoSubscriptionLabel>
								<div style={{ display: isMobile ? '' : 'flex', justifyContent: isMobile ? '' : 'center', alignItems: isMobile ? '' : 'center' }}>
									<AddPaymentButton onClick={() => history.push('/my-subscription/add-payment-method')}>ADD NEW CARD</AddPaymentButton>
								</div>
							</div>
						</PaymentMethodEmptyContainer>
						{isMobile && <SubscriptionSeparator />}
					</div>
					: ''
			}
			<CommonModalContainer isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
				<DeletePaymentMethodModal ending={selectToDeleteMethod.ending} isModalOpen={modalHandler} onDelete={deleteHandler} />
			</CommonModalContainer>

			{
				isDeleteSuccess &&
				<PaymentMethodEmptyContainer isMobile={isMobile}>
					<FormNotifications>
						<div className={'form-success deleted-success mb-0'}>
							<SuccessNotification>Payment method successfully removed. The page will automatically refresh in {seconds} seconds.</SuccessNotification>
						</div>
					</FormNotifications>
				</PaymentMethodEmptyContainer>
			}

			{toggleExpireModal && <NoticeModal messageBody={'Notice: Your subscription has expired. Please purchase a new subscription.'} title={'SUCCESSFULLY ADDED!'} isOpen={toggleExpireModal} onClose={handleExpireModal} isBlocked={false} />}

			{deleteLoading && <LoadingOverlay />}
			{isLoading && <LoadingOverlay />}
			{showNotification.show && <ErrorModal title={showNotification.message === 'Succesfully deleted the payment method' ? 'Successfully created!' : 'Error Message'} messageBody={showNotification.message} isOpen={showNotification.show} onClose={closeModal} />}
		</div >
	)
}

export default PaymentMethods	