import React from 'react'
import { Link } from 'react-router-dom'

import SocialMediaLinks from '../../components/social-media/SocialMediaLinks'
import { validateURL } from '../../utils/ValidateUrl'

import { ConsentContainer } from './styles'

const showDidomiPreferences = (): void => {

	if (window.Didomi && window.Didomi.notice && window.Didomi.preferences) {
		window.Didomi.preferences.show()
	}
}

const Footer: React.FC = () => (
	<footer className="site-footer site-footer--inverse inverse-text">
		<div className="site-footer__section">
			<div className="container">
				<div className="site-footer__section-inner text-center">
					<div className="site-logo">
						<Link to={'/'} >
							<img src="img/logo-mark-white.png" alt="logo" width="300" />
						</Link>
					</div>
				</div>
			</div>
		</div>
		<div className="site-footer__section">
			<div className="container">
				<nav className="footer-menu text-center">
					<ul id="menu-footer-menu" className="navigation navigation--footer navigation--inline">
						<li>
							<Link to={'/contact-us'}>Contact Us</Link>
						</li>
						<li>
							<a href={validateURL('https://docs.google.com/forms/d/e/1FAIpQLSf7TmQOaUSn1AmQNhgh5P6YNqhXEZdAPxeI890KQ1nngqP35g/viewform?c=0&w=1') ? 'https://docs.google.com/forms/d/e/1FAIpQLSf7TmQOaUSn1AmQNhgh5P6YNqhXEZdAPxeI890KQ1nngqP35g/viewform?c=0&w=1' : ''} target='_blank' rel='noreferrer'>Wine Submission</a>
						</li>
						<li>
							<Link to={'/cookie-notice'}>Cookie Notice</Link>
						</li>
						<li>
							<Link to={'/privacy-notice'}>Privacy Notice</Link>
						</li>
						<li>
							<Link target='_blank' to='/subscription-agreement?selectedPlan=personal'>Subscription Agreement</Link>
						</li>
						<li>
							<Link to={'/gift-subscription'}>Gift Subscriptions</Link>
						</li>
					</ul>
				</nav>
			</div>
			<br/>
			<nav className="footer-menu text-center">
				<ul id="menu-footer-menu" className="navigation navigation--footer navigation--inline">
					<li>
						<ConsentContainer onClick={showDidomiPreferences}>Cookie Preferences</ConsentContainer>
					</li>
					<li>
						<Link to={'/privacy-notice#exerciseyourprivacyrights'}>Limit the Use of My Sensitive Personal Information</Link>
					</li>
				</ul>
			</nav>
		</div>
		<div className="site-footer__section">
			<div className="container">
				<SocialMediaLinks containerClasses="social-list social-list--lg list-center" />
			</div>
		</div>
		<div className="site-footer__section">
			<div className="container">
				<div className="text-center">
					<p>The Wine Advocate Pte. Ltd. @ {new Date().getFullYear()} Singapore</p>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer
