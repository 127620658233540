/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import ProfileCard from '../components/component-cards/ProfileCard'
import PaymentCard from '../components/component-cards/PaymentCard'
import SubscriptionCard from '../components/component-cards/SubscriptionCard'
import { useGetCustomerPaymentMethodQuery, useGetTradeDirectoryLookupCountriesQuery, useGetUserLoginMutation, useSignUpExistingUserUpdgradeCommercialMutation, useSignUpNewUserMutation } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import ModalContainer from '../../../../../components/modal-container/ModalContainer'
import AddNewCard from '../../gift-subscription/gift-sub/components/AddNewCard'
import ErrorModal from '../../../../../components/error-modal'
import NoticeModal from '../../../../../components/notice-modal'
import { isUserAuthAndExpired, obfuscateConstant, trimmedEmails } from '../../../../../utils/Constant'
import { SaveAuthenticationSession } from '../../../../../utils'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { setCookie } from '../../../../../utils/CookieUtils'
import { delay, deviceType } from '../../../../../helper/constants'
import { UpdateUserAuthDetails } from '../../../../../app/slices/Authentication'
import { PLAN_ANALYTICS } from '../../../../../configs'
import { UpdateSuccessAnalytics } from '../../../../../app/slices/Analytics'
import { useGetPromoByCodeMutation } from '../../../../../app/services/Subscription'

import { GiftSubMainContainer, GiftSubTitle, PlanLabel } from '../../gift-subscription/styles'
import { isSubscriptionExpired } from '../../../../../app/services/constant'
import { setAccessLocalStorage } from '../../../../../utils/ValidateAccessToken'
import CustomModal from '../../../../../components/custom-modal/CustomModal'
import { ModalContent } from '../styles'

const plans = [
	{
		head: 'Personal Quarterly',
		price: 37,
		subText: 'Pay $37 every 3 months, save $32 a year',
		planId: 'personal-3-month',
		label: 'Quarterly'
	},
	{
		head: 'Personal Annual',
		price: 129,
		subText: 'Pay $129 a year, save $51',
		planId: 'personal-1-year',
		label: 'Annual'
	},
	{
		head: 'Personal Monthly',
		price: 15,
		subText: 'Pay $15 monthly',
		planId: 'personal-monthly',
		label: 'Monthly'
	}
]

type CountryType = {
	_id: string;
	name: string;
	code: string;
	tax_value: number | null;
}

function PersonalCheckout(): ReactElement {
	const recaptchaRef = useRef(null)
	const { type } = useParams<{ type: string }>()
	const { userData } = useAppSelector(UsersFromStore)
	const history = useHistory()
	const dispatch = useDispatch()

	const [countries, setCountries] = useState<string[]>([])
	const [listCountries, setListCountries] = useState<CountryType[]>([])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [showAddModal, setShowAddModal] = useState(false)
	const [formData, setFormData] = useState({})
	const [newCardDetails, setNewCardDetails] = useState<{ isNewCard: boolean, data: any }>({
		isNewCard: false,
		data: null
	})
	const [checkoutError, setCheckoutError] = useState({
		isError: false,
		message: ''
	})

	const [promoDetails, setPromoDetails] = useState({
		description: '',
		value: 0
	})
	
	const { handleSubmit, getValues, setValue, setError, control, clearErrors } = useForm()
	const { control: cardControl, handleSubmit: cardHandleSubmit, reset: cardReset } = useForm()

	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')
	const { data: paymentData, isLoading: paymentListLoading } = useGetCustomerPaymentMethodQuery(userData?._id, { skip: !userData?._id })
	const [userLoginMutation, { isLoading: userLoginLoading }] = useGetUserLoginMutation()
	const [signUpNewUserMutation, { isLoading: signUpLoading }] = useSignUpNewUserMutation()
	const [signUpExistingMutation, { isLoading: signUpExistingLoading }] = useSignUpExistingUserUpdgradeCommercialMutation()
	const [validateCodeMutation] = useGetPromoByCodeMutation()

	const isTrial = !userData?._id && type === 'personal-1-year'
	const planTypeData = plans.find(plan => plan.planId === type)
	const isLogged = userData ? true : false

	useEffect(() => {
		triggerTagManager('onLoad', '')
	}, [type])
	
	useEffect(() => {
		if (countryData?.success) {
			setListCountries(countryData.data)
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}
	}, [countryData])

	useEffect(() => {
		if(paymentData && paymentData.success){
			const paymentToken = paymentData?.data[0]?.token
			setValue('paymentMethodToken', paymentToken)
		}
	}, [paymentData])

	useEffect(() => {
		if(isModalOpen){

			const selectedPlan = PLAN_ANALYTICS.find(plan => plan.planId === type)
			const total = (Number(planTypeData?.price) - ((formData as any)?.promoCode ? promoDetails?.value : 0)) || 0

			const event = {
				event: 'view_cart',
				ecommerce: {
					currency: 'USD',
					value: selectedPlan?.planId === 'personal-1-year' ? selectedPlan?.originalPrice : total,
					items: [
						{
							recommended_plan: selectedPlan?.recommended,
							item_list_id: 'rpwa_personals_plans',
							item_list_name: 'RPWA personals plans',
							item_brand: 'Personal subscription',
							item_name: selectedPlan?.item_name,
							item_category: 'Personal',
							item_category2: selectedPlan?.item_category2,
							discount: selectedPlan?.planId === 'personal-1-year' ? undefined : (formData as any)?.promoCode ? promoDetails?.value : undefined,
							price: selectedPlan?.planId === 'personal-1-year' ? selectedPlan?.originalPrice : total
						}
					]
				}
			}

			// console.log('event:', event)

			TagManager.dataLayer({
				dataLayer: {
					ecommerce: null
				}
			})

			TagManager.dataLayer({
				dataLayer: event
			})
		}
	}, [isModalOpen])

	const loginUser = async (email: string, password: string, transactionId: string) => {
		try{
			const csrf_token = await getCSURFToken()

			const loginPayload = {
				username: email,
				password: password,
				device: deviceType(),
			}
			const loginResultData = await userLoginMutation({params: loginPayload, token: csrf_token}).unwrap()

			if(!loginResultData.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: loginResultData.message || loginResultData.data.message || JSON.stringify(loginResultData)
				})
			}
			
			SaveAuthenticationSession(
				'user',
				loginResultData.data.accessToken,
				loginResultData.data.accessTokenExpiresAt,
				loginResultData.data.refreshToken,
				loginResultData.data.refreshTokenExpiresAt,
				loginResultData.data.client._id,
				loginResultData.data.user._id
			)

			setAccessLocalStorage(loginResultData.data.accessToken, loginResultData.data.accessTokenExpiresAt, loginResultData.data.refreshToken, loginResultData.data.refreshTokenExpiresAt)

			dispatch(UpdateUserAuthDetails({
				token: loginResultData.data.accessToken,
				tokenExpiry: loginResultData.data.accessTokenExpiresAt,
				refreshToken: loginResultData.data.refreshToken,
				refreshTokenExpiry: loginResultData.data.refreshTokenExpiresAt,
				clientId: loginResultData.data.client._id,
				country: loginResultData.data.country,
				userId: loginResultData.data.user._id
			}))

			setCookie('username', getValues('email'), true)
			triggerTagManager('onSave', transactionId)
			history.push('/sign-up/success?type=personal')

		} catch(error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error.message ||  error.data.message || JSON.stringify(error)
			})
		}
	}

	const onSubmitHandler = async (formData: FieldValues) => {
		if (!formData.recaptcha)
			return setError('isAggrement', {
				type: ' custom',
				message: 'Recaptcha token is required.'
			})

		setFormData(formData)
		setIsModalOpen(true)
	}

	const cardFormSubmit = (cardDetails: FieldValues) => {
		if(cardDetails){
			setNewCardDetails({
				data: cardDetails,
				isNewCard: true,
			})
			setShowAddModal(false)
			setValue('paymentMethodToken', '')
			cardReset()
		}
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
		}
	}

	const triggerTagManager = async (trigerType: 'onLoad' | 'onSave', transactId: string) => {

		if(trigerType === 'onLoad'){
			const selectedPlan = PLAN_ANALYTICS.find(plan => plan.planId === type)

			if(selectedPlan){
			
				const event = {
					event: 'begin_checkout',
					ecommerce: {
						currency: 'USD',
						value: selectedPlan.originalPrice,
						items: [
							{
								index: 0,
								recommended_plan: selectedPlan.recommended,
								item_list_id: selectedPlan.item_list_id,
								item_list_name: selectedPlan.item_list_name,
								item_brand: selectedPlan.item_brand,
								item_name: selectedPlan.item_name,
								item_category: selectedPlan.item_category,
								item_category2: selectedPlan.item_category2,
								coupon: selectedPlan.coupon,
								discount: undefined,
								price: selectedPlan.originalPrice
							}
						]
					}
				}

				// console.log(`event: ${type}`, event)
				
				TagManager.dataLayer({
					dataLayer: {
						ecommerce: null
					}
				})
	
				TagManager.dataLayer({
					dataLayer: event
				})
			}
		}

		if(trigerType === 'onSave'){

			const promoCode = getValues('promoCode')
			let discountValue = 0

			if(promoCode){
				const promoDiscountValue = await validateCodeMutation({promoCode}).unwrap()

				if(promoDiscountValue.success) {
					discountValue = promoDiscountValue.data.discountValue

					dispatch(UpdateSuccessAnalytics({
						planId: type,
						seats: 0,
						tasting_notes: '',
						discountValue,
						transactionId: transactId,
						discountCoupon: obfuscateConstant(promoCode)
					}))
					return await delay(200)
				}

				dispatch(UpdateSuccessAnalytics({
					planId: type,
					seats: 0,
					tasting_notes: '',
					discountValue: 0,
					transactionId: transactId,
					discountCoupon: ''
				}))
				return await delay(200)
			} else {

				dispatch(UpdateSuccessAnalytics({
					planId: type,
					seats: 0,
					tasting_notes: '',
					discountValue: 0,
					transactionId: transactId,
					discountCoupon: ''
				}))
				return await delay(200)
			}
		}
	}

	const handleProceed = async(formData: any) => {
		const countrySelected = countryData.data.find((country: any) => country.name === formData.country)
		const promoCode = formData.promoCode || ''
		const normalizeEmail = trimmedEmails(formData?.email)
		
		const commonPayload: any = {
			title: '',
			email: normalizeEmail,
			password: formData.password,
			firstName: formData.firstName,
			lastName: formData.lastName,
			receive_newsletter: formData.isNewsLetter ? 1 : 0,
			receive_marketingletter: formData.isMarketting ? 1 : 0,
			promoCode: promoCode,
			companyName: formData.companyName,
			companyTypes: formData.companyTypes,
			phone: formData.phone,
			fax: '',
			website: '',
			country: countrySelected,
			planId: type,
			recaptcha_token: formData.recaptcha,
		}

		let payload: any = {
			...commonPayload,
			card: {
				name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
				number: formData.cardNumber,
				expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
				cvv: formData.cvv,
				postalCode: formData.zipCode,
				paymentMethodToken: ''
			}
		}

		if(isLogged && newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: newCardDetails.data.cardHolderFirstName + ' ' + newCardDetails.data.cardHolderLastName,
					number: newCardDetails.data.cardNumber,
					expirationDate: newCardDetails.data.expiryMonth + '/' + newCardDetails.data.expiryYear,
					cvv: newCardDetails.data.cvv,
					postalCode: newCardDetails.data.zipCode,
					paymentMethodToken: ''
				},
				
			}

		if(isLogged && !newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: '',
					number: '',
					expirationDate: '',
					cvv: '',
					postalCode: '',
					paymentMethodToken: formData.paymentMethodToken
				}
			}

		if(userData && (!paymentData || (paymentData && !paymentData.success) || (paymentData && paymentData.success && !paymentData.data.length))){
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
					number: formData.cardNumber,
					expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
					cvv: formData.cvv,
					postalCode: formData.zipCode,
					paymentMethodToken: ''
				}
			}
		}

		try {

			const csrf_token = await getCSURFToken()

			if(isLogged){
				const signUpExistingUser = await signUpExistingMutation({ params: payload, token: csrf_token }).unwrap()

				if(!signUpExistingUser.success){
					handleRecaptchaReset()
					return setCheckoutError({
						isError: true,
						message: signUpExistingUser.message || signUpExistingUser.data.message || JSON.stringify(signUpExistingUser)
					})
				}

				if(signUpExistingUser.success){
					const subscription = signUpExistingUser.data
					const transactionid = subscription.transactions && subscription.transactions.length ? subscription.transactions[0].id : ''

					triggerTagManager('onSave', transactionid)
					history.push('/sign-up/success?type=personal')
				}
			}

			const signUpNewUser = await signUpNewUserMutation({ params: payload, token: 'csrf_token' }).unwrap()

			if(!signUpNewUser.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: signUpNewUser.message || signUpNewUser.data.message || JSON.stringify(signUpNewUser)
				})
			}

			if(signUpNewUser.success){
				const subscription = signUpNewUser.data.subscription
				const transactionid = subscription.transactions && subscription.transactions.length ? subscription.transactions[0].id : ''
				await loginUser(payload.email, payload.password, transactionid)
			}
			
		} catch (error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error?.message || error?.data?.message || JSON.stringify(error)
			})
		}
	}

	const showBlockSubscriptionModal = () => {
		if(userData){
			if(userData.orbit_roles.includes('project:customer-commercial'))
				return true

			if(!userData.subscription)
				return false

			const isExpired = isSubscriptionExpired(userData.subscription.end)

			if (!isExpired) {
				return true
			}
		}
		return false
	}

	return <>
		{paymentListLoading && <LoadingOverlay />}
		{userLoginLoading && <LoadingOverlay />}
		{signUpLoading && <LoadingOverlay />}
		{signUpExistingLoading && <LoadingOverlay />}
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<GiftSubMainContainer isMobile={isMobile}>
					
					<GiftSubTitle isMobile={isMobile}>
						<p>
							<PlanLabel leftAdjustment={type == 'personal-3-month' ? 337 : 347} onClick={() => history.goBack()} >Personal Offers</PlanLabel>
							{isMobile
								? `Personal ${planTypeData?.label} Subscription - $${planTypeData?.price}`
								: `${planTypeData?.label} Personal Subscription Checkout - $${planTypeData?.price}`
							}
						</p>
					</GiftSubTitle>
					<form className='gift-grid-container' onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='gift-grid'>
							<ProfileCard control={control} isLogged={userData?._id ? true : false} countries={countries} getValues={getValues} headerText='1. Personal Information' userData={userData} setValue={setValue}  countryList={listCountries}/>
							<PaymentCard control={control} isLogged={userData?._id ? true : false} headerText='2. Payment Method' paymentData={paymentData} onAddNewCard={() => setShowAddModal(true)} newCardDetails={newCardDetails} />
						</div>
						<div className="gift-grid">
							<SubscriptionCard onPromoDetails={setPromoDetails} clearErrors={clearErrors} total={isTrial ? 0 : planTypeData?.price || 0} control={control} recaptchaRef={recaptchaRef} setValue={setValue} getValues={getValues} headerText='3. Subscription' setError={setError} planType={type} isLogged={userData?._id ? true : false}>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>{planTypeData?.head}</span>
									<span style={{ fontWeight: 'normal' }}>${planTypeData?.price}</span>
								</div>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>{planTypeData?.subText}</span>
								</div>
								{ isTrial && <div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>1 Week Free Trial</span>
									<span style={{ fontWeight: 'normal', color: '#BCA76F' }}>-$129</span>
								</div>}
							</SubscriptionCard>
						</div>
					</form>
				</GiftSubMainContainer>
			</div>
			{checkoutError.isError && <ErrorModal messageBody={checkoutError.message} isOpen={checkoutError.isError} onClose={() => setCheckoutError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' />}
			{showBlockSubscriptionModal() &&
				<NoticeModal
					messageBody={`You currently have an active subscription that ends on ${moment(new Date(userData?.subscription?.end)).format('MMMM DD, YYYY')}. You can manage renewal of your subscription in Manage subscription.`}
					title={'NOTICE MESSAGE'}
					isOpen={isUserAuthAndExpired(userData)} onClose={() => history.push('/my-subscription')} isBlocked={true} />
			}

			{isModalOpen &&
				<CustomModal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					onProceed={() => handleProceed(formData)}
					rigthButtonLabel='COMPLETE YOUR PURCHASE'
					width={564}
				>
					<ModalContent>
						<div className='header'>Order Review</div>
						<div className='sub-header'>You will be paying for the following products</div>
						<div className='plan-container flex-container'>
							<div className='plan-label common-styles'>{planTypeData?.head} (Auto-Renew)</div>
							<div className='plan-value common-styles'>${planTypeData?.price}</div>
						</div>
						{isTrial ?
							<div className='promo-container flex-container'>
								<div className='promo-label common-styles'>1 Week Free Trial</div>
								<div className='promo-value common-styles trial-color'>-$129</div>
							</div>
							:
							promoDetails?.value > 0 &&
							<div className='promo-container flex-container'>
								<div className='promo-label common-styles'>Promo code ({(formData as any)?.promoCode || 'none'})</div>
								<div className='promo-value common-styles'>- ${(formData as any)?.promoCode ? promoDetails?.value : 0}</div>
							</div>
						}

						<div className='separator' />
						<div className='total flex-container'>
							<div className='total-label'>Total</div>
							<div className='total-value'>${isTrial ? 0 : (Number(planTypeData?.price) - ((formData as any)?.promoCode ? promoDetails?.value : 0)) || 0}</div>
						</div>
					</ModalContent>
				</CustomModal>
			}

			<ModalContainer isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
				<AddNewCard control={cardControl} handleSubmit={cardHandleSubmit} onSubmit={cardFormSubmit} onClose={() => setShowAddModal(false)} />
			</ModalContainer>
		</div></>
}

export default PersonalCheckout
