/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Control, FieldValues, RegisterOptions, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router-dom'

// import Input from '../../../../../../components/form-components/input/Input'
import CheckoutButton from '../../../../../../components/form-components/buttons/CheckoutButton'
import Checkbox from '../../../../../../components/form-components/checkbox/Checkbox'
import { reverseObfuscateConstant } from '../../../../../../utils/Constant'
import { RP_ENV } from '../../../../../../configs'
// import { useGetPromoCodeValidationQuery } from '../../../../../../app/services'

import { Seperator } from '../../../subscriptions/styles'
import { GiftCardContainer, GiftCardFormBody } from '../../styles'

type Props = {
    control: Control<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	setError: UseFormSetError<FieldValues>;
	recaptchaRef: MutableRefObject<null>
}

const aggrementCheckboxRules: RegisterOptions = {
	required: 'Please check aggrement to proceed.',
	validate: (value) => value !== false || 'Please check the aggrement'
}

function SubscriptionDetails({control, setValue, getValues, setError, recaptchaRef}: Props):ReactElement {

	// const [promoCode, setPromoCode] = useState('')
	// const [promoCodeMessage, setPromoCodeMessage] = useState('')
	const history = useHistory()

	// const { data } = useGetPromoCodeValidationQuery(promoCode, { skip: !promoCode })
	
	// useEffect(() => {
	// 	if(data){
	// 		if(data.success){
	// 			setPromoCodeMessage(data.message)
	// 		}
	// 		else {
	// 			setError('promoCode' ,{
	// 				type: 'custom',
	// 				message: data.message
	// 			})
	// 			setPromoCodeMessage('')
	// 		}
	// 	}
	// }, [data])

	// const onVerifyCode = () => {
	// 	const promoCode = getValues('promoCode')
	// 	if(promoCode){
	// 		setPromoCode(promoCode)
	// 	}
	// }

	const triggerLink = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
		e.preventDefault()
		history.push(url)
	}

	return <GiftCardContainer isMobile={isMobile}>
		<GiftCardFormBody isMobile={isMobile}>
			<div className='head-body'>
				<h3>4. Subscription</h3>
			</div>
			<div className='form'>
				<div className='text-field' style={{ marginBottom: '1rem' }}>
					<span>Gift Subscription - 1 Year</span>
					<span>$129</span>
				</div>
				<Seperator />
				{/* <div className='text-field' style={{ marginTop: '1.5rem' }}>
					<Input control={control} name='promoCode' type='text' placeholder='Promo Code(optional)' />
					<CheckoutButton name='Apply' type='button' width='8rem' height='3.9rem' bgColor='white' textColor='black' onClick={() => onVerifyCode()}/>
					{promoCodeMessage && <p style={{ color: 'lightgreen' }}>{promoCode}</p>}
				</div> */}
				{/* <Seperator /> */}
				<div className='text-field' style={{ marginBottom: '1rem', marginTop: '1rem' }}>
					<span>Total</span>
					<span>$129</span>
				</div>
				<div className='first-input'>
					<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
						{/* <span style={{ fontWeight: 'normal' }}>I have read and agreed to the terms of the <a href='/subscription-agreement' target='__blank'>Gift Subscription Agreement</a>.</span> */}
						<span >
							By checking this box and subscribing, I agree to the Robert Parker Wine Advocate&nbsp;
							<a className="bold-link" href='/subscription-agreement' target='__blank'>Gift Subscription Agreement</a>.
							<br />
						</span>
					</Checkbox>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className='action-input'>
						<div className='recaptcha-style'>
							<i>Please check the box below to proceed.</i>
							<ReCAPTCHA
								sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
								onChange={(token) => setValue('recaptcha', token)}
								ref={recaptchaRef}
							/>
						</div>
						<CheckoutButton name='PROCEED TO CHECKOUT' type='submit' />
					</div>
				</div>
				<i style={{ marginTop: '1.5rem', fontWeight: 600 }}>
					To find out how we use and process the data you entrust to us, please refer to our <a className='bold-link italic-link' href='/privacy-notice' onClick={e => triggerLink(e, '/privacy-notice')} > privacy policy</a>.
				</i>
			</div>
		</GiftCardFormBody>
	</GiftCardContainer>
}

export default SubscriptionDetails
