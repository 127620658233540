import { IsUserAuthenticated } from '../utils'
import { reverseObfuscateConstant } from '../utils/Constant'
import { AlgoliaFacetTitleTypes } from './types'

export const WINE_ADVOCATE_PUBLICATION_ID = 'HpMNMt2WYpJGy56Nj'
export const WINE_JOURNAL_PUBLICATION_ID = 'dBCLniZGrsg6C683M'

export const STATIC_MESSAGES = {
	PAYWALL_TITLE: 'This content is exclusively available to our subscribers. ',
	// ARTICLE_PAYWALL_DESC: 'Unlock premium articles and experience the finest quality wine journalism through our exclusive subscription service, and enjoy a complimentary one-month trial subscription.',
	// WINE_PAYWALL_DESC: 'Unlock more that 450000 wine ratings and tasting notes from our Wine Advocate experts, and enjoy a complimentary one-month trial subscription.',
	ARTICLE_PAYWALL_DESC: 'Unlock premium articles and experience the finest quality wine journalism through our exclusive subscription service.',
	WINE_PAYWALL_DESC: 'Unlock more that 450000 wine ratings and tasting notes from our Wine Advocate experts.',

	SUBSCRIBE: 'Subscribe',
}

export const RP_ENV = {
	API_KEY: '7ZPWPBFIRE2JLR6JBV5SCZPW54ZZSGGY',
	ALGOLIA_API_KEY: '931818f324:4d6::5c36c1dbg44g12gf',
	ALGOLIA_APPLICATION_ID: 'FUYCIQTJQG',
	WINE_INDEX: 'qspe`xjoft',
	ARTICLE_INDEX: 'qspe`bsujdmft',
	// API_URL_V2: 'iuuq;00mpdbmiptu;9191',
	// API_URL_V2: 'iuuqt;00sq.xjof.w3/kbn358/efw0',
	API_URL_V2: 'iuuqt;00xxx/spcfsuqbslfs/dpn',
	GTM_ID: 'HUN.Q4C6D4X',
	CAPTCHA_KEY: '7MeHIFJpBBBBBH3yjL{WdOOXJDryBW1gZ5`PN{wf'
	// DIDOMI_KEY: 'ed848d8c.3e5f.515f.971d.42dgg1b767fd',
	// WEBAPP_CLIENT_ID: 'XDNQ5H2T6PREHPHDAJL2WD8GWWWC8KQC', // declared but not used
	// REVERSE_GEOCODING_API_KEY: '80e451243f3c43bdac9f037c03587bde', // declared but not used
	// WEBAPP_CLIENT_SECRET: '1ak-daAr4TE0o03_9eZj81AJKJXKYPAu', // declared but not used
	// AUTH_API_URL: 'auth.robertparker.com', // declared but not used
	// INVISIBLE_RECAPTCHA_KEY: '6Lfd3bwUAAAAAJHbmtqWF8uZQJ-d7E6V7aJnX_FZ', // declared but not used
	// GOOGLE_API_KEY: 'AIzaSyCBbtAxTfw8lx64Ti1kc4WlRrdPhEazq_w', // declared but not used
}

export const APP_CONFIG = {
	ADVANCED_SEARCH: {
		FILTERS: {
			WINE: [
				'Rating',
				'Vintage',
				// 'Reviewer',
				// 'Issue',
				'Country / Region / Appellation',
				// 'Producer',
				// 'Wine Type',
				'Color',
				// 'Variety',
				// 'Sweetness',
				// 'Certified',
			],
			ARTILCES: ['one', 'two'],
		},
	},
	ARCHIVES: {
		MAX_LIST: 5,
	},
	ARTICLES: {
		TWA_MAX_LIST: 5,
	},
	AUTHENTICATION: {
		SESSION_LABEL: 'RPWA_AUTH',
	},
	AUTHORS: {
		MAX_RECENT_PREVIEW: 3,
	},
	BRAINTREE: {
		AUTHORIZATION: 'production_p55z9r2y_g84tnszx38s5sghw',
	},
	DEFAULTS: {
		MAX_LIMIT: 1000,
		DATE_FORMAT: 'MMM DD, YYYY',
	},
	FREE_TO_READ_PUBLICATION: 'dBCLniZGrsg6C683M',
	RETAILERS: {
		MAX_LIST: 5,
	},
	SEARCH: {
		TYPES: {
			ARTICLE: 'article',
			WINE: 'wine',
			PRODUCER: 'producer',
		},
		QUERY_STRING_FACETS: [
			'vintage',
			'reviewers',
			'country',
			'region',
			'sub_region',
			'appellation',
			'sub_appellation',
			'producer',
			'varieties',
			'maturity',
			'type',
			'color_class',
			'dryness',
			'tags',
			'certified',

			'tags',
			'regions',
			'countries',
			'author.name',
			'issue_title',
		],
		ARTICLE_SORT: '&sort=published_date&order=desc',
		WINE_SORT: '&sort=max-price&order=desc',
		OFFSET_LIMIT: { offset: 0, limit: 50 },
		SELECTED_WINE_LIMIT: {offset: 0, limit: 50},
		ALTERNATE_OFFSET_LIMIT: { offset: 0, limit: 50},
		SORT_FILTERS: {
			WINE: [
				{
					label: 'Latest Reviews',
					value: 'latest_review',
					order: 'desc',
					query_param: 'vintage',
				},
				{
					label: 'Vintage (Latest first)',
					value: 'vintage_high',
					order: 'desc',
					query_param: 'vintage',
				},
				{
					label: 'Vintage (Lowest first)',
					value: 'vintage_low',
					order: 'asc',
					query_param: 'vintage',
				},
				{
					label: 'Rating (Highest first)',
					value: 'rating_high',
					order: 'desc',
					query_param: 'min-rating',
				},
				{
					label: 'Rating (Lowest first)',
					value: 'rating_low',
					order: 'asc',
					query_param: 'min-rating',
				},
			],
			ARTICLES: [
				{
					label: 'Publish date (Latest first)',
					value: 'publish_date_latest',
					order: 'desc',
					query_param: 'published_date',
				},
				{
					label: 'Publish date (Earliest first)',
					value: 'publish_date_earliest',
					order: 'asc',
					query_param: 'published_date',
				}
			],
			PRODUCERS: [
				{
					label: 'Name (A to Z)',
					value: 'asc',
				},
				{
					label: 'Name (Z to A)',
					value: 'desc',
				},
			],
		},
		MAX_TO_DISPLAY_ON_LEFT: 5,
	},
	SUBSCRIPTION: {
		QUANTITIES: [0, 1, 2, 3, 4, 5, 6, 7],
		TITLES: ['Mr.', 'Mrs.', 'Ms.', 'Dr.'],
		STATUS_DISABLED: 'Canceled',
	},
	TASTING_NOTES: {
		MAX_PAGE_ON_NAV: 5,
		MAX_ITEM_PER_PAGE: 1,
	},
	TWA_PUBLICATION: WINE_ADVOCATE_PUBLICATION_ID,
	WINE_JOURNAL_CATEGORIES_ORDERING: [
		'News & Views',
		'Restaurants',
		'Travel',
		'People & Places',
		'Culture & Education',
		'Dining At Home',
		'Events',
	],
}

export const API_ENDPOINTS = {
	// articles
	get_more_free_stuff: 'article/list-highlighted?%s',
	get_more_free_stuffv2: '/article/list-recommended',
	get_recommended_reads_v2: '/article/list-recommended',
	get_featured_wine_advocate:'/article/list-all?offset=0&limit=20',
	get_article_highlights:'/article/list-highlighted',
	get_article_by_id: '/article/get-details?articleId=%s',
	get_articles_from_keywords: 'articles?%s',
	get_articles_by_author: 'articles?%s',
	get_article_contents_by_id: 'articles/%s/content',
	get_article_comments: 'article/get-article-comment-count?%s',
	get_article_tags: 'article/get-article-tags?%s',
	get_recommended_reads: 'articles?is_recommended=true&limit=5',
	get_wine_journal_articles:'/article/list-wine-journals',
	get_list_authors:'/article/list-author',
	get_featured_wine_journal_articles:'/article/list-wine-journals-review-2023',
	get_wines_from_article: 'articles/%s',
	get_article_by_author: '/article/list-by-author',
	get_list_wine_journal:'/article/list-wine-journals',

	// wines
	get_wine_details:'/wine/get-details?wineId=%s',
	get_published_articles:'/wine/list-articles?wineId=%s',
	get_filters_for_wine: 'wines-filters?filter=%s',
	get_wine_varieties: 'wine/get-varieties',
	get_wine_vintages: 'wine/get-vintages?wine_id=%s',
	get_wines_from_filter: 'wines-filters?%s&filter=wine_groups',
	get_wines_from_issue: 'wines?limit=250',
	get_wines_by_location: 'wine/get-prices?name=%s',
	get_wine: 'wines/%s',
	get_tasting_notes_from_wine:
		'wines/%s/tasting_notes?sort=published_date&order=desc',

	// issue
	get_articles_from_issue: 'issues/%s/articles',
	get_issue_details: '/issue/list-prev-next?issueId=%s',
	get_latest_issue:'/issue/list-all?offset=0&limit=1',
	get_issues: '/issue/list-all',
	get_issue_detailsByID: '/issue/get-details',
	get_article_issues: '/article/list-by-issue',

	// user
	update_user_company: '/user/company',
	update_user_profile: '/user/profile',
	update_user_password: '/user/password',
	update_user_passwordV2:'/user/update-password',
	sign_up: '/user/sign-up',
	get_user_details: '/user/get-details',
	get_reviewers_details: '/user/get-reviewer-details',
	update_company: '/user/company',
	get_list_user_reviewers: '/user/list-reviewers',
	get_user_login_details: '/user/login',
	user_forgot_password: '/user/forgot-password',
	user_change_password: '/user/change-password',
	user_update_profile: '/user/update-user-particulars',
	user_update_preference: '/user/update-user-preferences',
	user_no_ccpa: '/user/toggle-no-ccpa',
	refresh_access_token: '/user/refresh-access',

	// user-company
	get_trade_directory_lookup_typesV2:'/user-company/list-business-types',
	get_user_company_details: '/user-company/get-details?companyId=%s',
	get_user_company_by_manager: '/user-company/get-details-by-manager',
	get_trade_directoryV2: '/user-company/list-all?',
	get_trade_directory_lookup_countries:'/user-company/list-countries',
	get_country_details:'/user-company/get-country-details',
	edit_user_company: '/user-company/update',
	upload_company_logo: '/user-company/upload',

	// braintree
	create_payment_method_braintree: '/braintree/add-payment-method',
	update_payment_method:'/braintree/edit-payment-method',
	set_default_payment_method:'/braintree/set-default-payment-method',
	delete_payment_method_braintree:'/braintree/delete-payment-method',
	get_payment_method: '/braintree/list-payment-methods?customerId=%s',
	get_specific_payment:'/braintree/find-payment-method?paymentMethodToken=%s',
	get_payment_receipt: '/braintree/get-receipt',

	// subscription
	get_subscripton_from_user_id: 'subscription/find-customer-subscription?customerId=%s',
	get_subscription_history: 'subscription/list-subscriptions?customerId=%s',
	get_user_subscription: 'subscription/find-customer-subscription?customerId=%s',
	get_subscription_details: '/subscription/find-subscription',
	update_subscription: 'update-subscription',
	check_end_subscription: '/subscription/check-end-subscription',
	cancel_subscription: '/subscription/cancel-subscription',
	get_promo_code_validation: '/promo-code/verify-discount',
	create_gift_subs_request: '/user/buy-gift-sub',
	activate_new_user_gift_subs: '/user/buy-gift-sub/activation-new-user',
	activate_existing_user_gift_subs: '/user/buy-gift-sub/activation-existing-user',
	get_promo_code_with_plan: '/promo-code/verify-discount',
	sign_up_new_user: '/user/sign-up',
	sign_up_existing_user: '/user/sign-up-existing',
	verify_redeem_code: '/promo-code/verify-redeemable',
	redeem_AuthenticatedUser: '/user/redeem-promo-code-existing-user',
	redeem_NotAuthenticatedUser: '/user/redeem-promo-code',
	update_auto_renewal: '/user/update-auto-renewal',
	change_subscription_plan: '/subscription/change-plan',

	// manage-subscriptions
	buy_add_ons: '/subscription/update-addons',
	reduce_addons: '/subscription/reduce-addons',
	trigger_reduce_addons: '/subscription/trigger-reduce-addons',

	// carousel
	get_featured_banner: '/carousel/get-featured-banner',
	get_list_home_banners: '/carousel/list-home-banners',

	// payment
	create_payment_method: '/create-payment-method?%s',
	delete_payment_method: 'delete-payment-method?token=%s',
	get_payment_history: '/payment/list-payment-history?customerId=%s',

	// publications
	get_free_to_read: `publications/${WINE_JOURNAL_PUBLICATION_ID}/articles?is_highlighted=1&limit=100`,
	get_latest_twa_issue: `publications/${WINE_ADVOCATE_PUBLICATION_ID}/issues?limit=1`,
	get_all_twa_issues: `publications/${WINE_ADVOCATE_PUBLICATION_ID}/issues?limit=1000`,

	// companies
	get_companies_with_eco_distinctions: 'companies/sustainability/eco-distinction',
	get_green_emblem_company: 'companies/sustainability/eco-distinction/%s',
	search_producers: '/company/search-producers?keyword=%s',
	get_producers: 'company/get-producers-by-id?%s',
	get_eco_details: '/company/get-eco-details',
	get_list_eco:'/company/list-eco',
	get_company_details:'/user/get-my-company',

	// Company Group
	get_company_group:'/user-group/list-members',
	remove_user_from_group:'/user-group/remove-user',
	add_user_from_group:'/user-group/add-user',
	join_group_invitation:'/user-group/get-group-by-token',
	join_group_submit_new:'/user-group/join-group-new',
	join_group_submit_exist:'/user-group/join-group-existing',
	reinvite_group_user: '/user-group/reinvite-group-member',

	// tasting-note
	get_author_tasting_note_count: 'tasting-note/get-tasting-note-count?author_id=%s',
	get_tasting_notes_from_wine_v2: '/tasting-note/list-wine-notes?wineId=%s',
	get_producer_notes: 'tasting-note/get-producer-notes?article_id=%s',

	add_user_company: 'add-user-company',
	add_user_orbit_role: 'user/add-user-orbit-role',
	create_user_profile: '/signup',
	create_user_subscription: '/create-subscription?%s',
	get_articles_from_query: `/${reverseObfuscateConstant(RP_ENV.ARTICLE_INDEX)}/query`,
	get_authentication_token: 'oauth/token',
	get_author_counts: 'author-counts?author_id=%s',
	get_author_article_count: 'article/get-author-article-count?author_id=%s',
	get_author_list: 'author-list',
	get_carousel_banner: 'banners',
	get_chart_data: '/vintage_chart?show_all=true',
	get_chart_data_v2:'/vintage-score/list-all',
	get_discount_code: 'get-discount-code?code=%s',
	get_editorial_calendar: 'calendar/get-editorial-calendar',
	get_password_revery: `${'me/forgot-password'}`,
	get_promo_code: 'get-promo-code?code=%s',
	get_retailers_of_wine: 'wine-prices?%s',
	get_reviewers: 'reviewers',
	get_trade_directory: 'get-trade-directory',
	get_trade_directory_lookup_types: 'get-trade-directory-lookup-types',
	get_user_company: '/get-my-company?userId=%s',
	get_user_profile: '/me',
	get_wines_from_query: `/${reverseObfuscateConstant(RP_ENV.WINE_INDEX)}/query`,
	search_appellations: '/appellation/search-appellations?keyword=%s',
	update_credit_card: 'update-credit-card',
	update_user_preference: '/update-user-preferences',
	update_personal_information: '/toggle-no-ccpa',
	list_recommended_articles: '/list-recommended-articles',
}

export const ARTICLE_PUBLICATION_SHORTNAMES = [
	{
		id: 'GnRcfcbc3caaf56d5421',
		shortName: 'ArticlesOfMerit',
	},
	{
		id: 'HpMNMt2WYpJGy56Nj',
		shortName: 'TheWineAdvocate',
	},
	{
		id: 'gNYLx43MGnahaQqM4',
		shortName: 'HedonistGazette',
	},
	{
		id: 'SKshpJ9zShfNgRydM',
		shortName: 'OtherPublications',
	},
	{
		id: 'dBCLniZGrsg6C683M',
		shortName: 'WineJournal',
	},
]

export const BLURBS = {
	WINE_PAGE_BOTTOM_BLURB_TITLE: 'About Maturity and Drink Dates',
	WINE_PAGE_BOTTOM_BLURB_CONTENT:
		'Most reviews in The Wine Advocate include a period during which the wine should ideally be consumed. We express it as a range of years (Drink Dates) and we use that range to calculate a "Maturity" for the wine as of the current date. Maturity values are: Young - the early drink date is in the future; Early - first third of the drink date range; Mature - middle third of the range; Late - last third of the range; Old - the late drink date is in the past',
}

export const BUSINESS_TYPES = [
	'Agent',
	'Auction House',
	'Broker',
	'Distributor',
	'Educator',
	'F&B',
	'Hotel',
	'Hotel-Restaurant',
	'Importer',
	'Negociant',
	'Producer',
	'Restaurant',
	'Retailer',
	'Vineyard',
	'Wholesaler',
	'Wine School',
	'Others',
]

export const EXTERNAL_LIKS = {
	EVENTS_PAGE: 'https://events.robertparker.com/',
	RP_CELLAR: 'http://cellar.robertparker.com/',
}

export const EXEMPTED_FILTER = 'wine_groups'

export const MARKETING_MATERIAL_REFERENCES = {
	LOGOS:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/rpwa-sample-logos.png',
	STICKER_90:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/RP90.png',
	STICKER_95:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/RP95.png',
	STICKER_99:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/RP99.png',
	STICKER_100:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/RP100.png',
	GUIDELINE:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/misc/rpwa-tasting-notes-1.png',
	GUIDELINE_PDF:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/guidelines/rpwa__brand-guidelines.pdf',
	LOGO_TOOLKIT:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/guidelines/rpwa__logo-kit.zip',
}

export const VINTAGE_CHART = {
	PDF: 'https://rp-devops-prod.s3.amazonaws.com/rp-wa/VintageChart_print.pdf',
}

export const PLANSv2 = {
	PERSONAL_PLANS: [
		{
			plan: 'wine-beginners',
			price: '$15',
			due: 'Month',
			billed: 'BILLED MONTHLY - $180/YEAR',
			features: [
				'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
				'Access to over 450,000 tasting notes covering regions all over the world',
				'Wine vintage charts dating back to the 1970s',
			],
			title: 'Monthly',
			button_text: 'SUBSCRIBE',
			numericPrice: 15,
			isShow: true,
			isRecommended: false,
			planId: 'personal-monthly',
		},
		{
			plan: 'wine-sommeliers',
			price: '$11',
			due: 'Month',
			billed: 'BILLED ANNUALLY - $129/YEAR',
			features: [
				' Pay $129 a year, save $51',
				'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
				'Access to over 450,000 tasting notes covering regions all over the world',
				'Wine vintage charts dating back to the 1970s',
			],
			title: 'Annual',
			button_text: 'SUBSCRIBE',
			numericPrice: 11,
			isShow: true,
			isRecommended: true,
			planId: 'personal-1-year'
		},
		{
			plan: 'wine-enthusiasts',
			price: '$12.30',
			due: 'Month',
			billed: 'BILLED QUARTERLY - $148/YEAR',
			features: [
				'Pay $37 every 3 months, save $32 a year',
				'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
				'Access to over 450,000 tasting notes covering regions all over the world',
				'Wine vintage charts dating back to the 1970s',
			],
			title: 'Quarterly',
			button_text: 'SUBSCRIBE',
			numericPrice: 12.3,
			isShow: true,
			isRecommended: false,
			planId: 'personal-3-month'
		},
	],
	COMMERCIAL_PLANS: [
		{
			plan: 'commercial-basic',
			price: '$249',
			due: 'Year',
			billed: 'BILLED ANNUALLY',
			features: [
				'All features from Personal subscription',
				'Priority booking and opportunity to exhibit at Matter of Taste events',
				'Right to purchase RP rating stickers to display on bottles',
				'Marketing collaterals & usage of our logos, tasting notes and wine scores',
				'Option to add users for your company staff at the price of $129 per user'
			],
			title: 'Commercial Basic',
			button_text: 'SUBSCRIBE',
			numericPrice: 249,
			isShow: true,
			isRecommended: true,
			planId: 'commercial-1-year'
		},
		// {
		// 	plan: 'commercial-consult',
		// 	price: '$249',
		// 	due: 'Year',
		// 	billed: '',
		// 	features: [
		// 		'Unlimited reading and consultations of articles by wine experts, tasting notes, and vintage wine charts',
		// 		'Option to add 2 discounted seats to your subscription for company staff',
		// 		'Rights to publish and print up to 30 scores, line reviews, and drinking windows',
		// 	],
		// 	title: 'I Consult',
		// 	button_text: 'SUBSCRIBE',
		// 	numericPrice: 249,
		// 	isShow: true,
		// 	isRecommended: false,
		// },
		// {
		// 	plan: 'commercial-guide',
		// 	price: '$500',
		// 	due: 'Year',
		// 	billed: '',
		// 	features: [
		// 		'Unlimited reading and consultations of articles by wine experts, tasting notes, and vintage wine charts ',
		// 		'Option to add 4 discounted seats to your subscription for company staff',
		// 		'20% off on one RPWA event happening during the year',
		// 		'More features coming soon!',
		// 	],
		// 	title: 'I Guide',
		// 	button_text: 'SUBSCRIBE',
		// 	numericPrice: 500,
		// 	isShow: true,
		// 	isRecommended: true,
		// },
		// {
		// 	plan: 'commercial-promote',
		// 	price: '$2000',
		// 	due: 'Year',
		// 	billed: '',
		// 	features: [
		// 		'Unlimited reading and consultations of articles by wine experts, tasting notes, and vintage wine charts',
		// 		'Option to add 40 discounted seats to your subscription for company staff',
		// 		'Rights to publish and print up to 1500 scores, line reviews, and drinking windows',
		// 		'VIP access to our marketing materials',
		// 	],
		// 	title: 'I Promote',
		// 	button_text: 'SUBSCRIBE',
		// 	numericPrice: 2000,
		// 	isShow: true,
		// 	isRecommended: false,
		// },
	],
}


export const PLANS = [
	{
		planId: 'personal-monthly',
		price: '$15',
		agreementName: 'Personal Subscription Agreement',
		due: 'monthly',
		billed: 'BILLED MONTHLY',
		description:
			'Get full access and updates to all our articles and tasting notes on the website and apps',
		button_text: 'SUBSCRIBE',
		label: 'Personal Monthly',
		checkout_label: 'Monthly Personal Checkout',
		slug: 'personal-monthly',
		title: 'FOR WINE BEGINNERS',
		subscriptionType: 'PERSONAL SUBSCRIPTION',
		numericPrice: 15,
		data: [
			'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
			'Access to over 450,000 tasting notes covering regions all over the world',
			'Wine vintage charts dating back to the 1970s',
		],
		isShow: true,
	},
	{
		planId: 'personal-3-month',
		price: '$37',
		agreementName: 'Personal Subscription Agreement',
		due: 'quarterly',
		billed: 'BILLED EVERY 3 MONTHS',
		description:
			'Get full access and updates to all our articles and tasting notes on the website and apps',
		button_text: 'SUBSCRIBE',
		label: 'Personal Quarterly',
		checkout_label: 'Quarterly Personal Checkout',
		slug: 'personal-3-month',
		title: 'FOR WINE BEGINNERS',
		subscriptionType: 'PERSONAL SUBSCRIPTION',
		numericPrice: 37,
		data: [
			// '30 days free trial for new users of the site!',
			'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
			'Access to over 450,000 tasting notes covering regions all over the world',
			'Wine vintage charts dating back to the 1970s',
		],
		isShow: true,
	},
	{
		planId: 'personal-1-year',
		price: '$129',
		agreementName: 'Personal Subscription Agreement',
		due: 'year',
		billed: 'BILLED ANNUALLY',
		description:
			'Get full access and updates to all our articles and tasting notes on the website and apps',
		button_text: 'SUBSCRIBE',
		label: 'Personal Annual',
		checkout_label: 'Annual Personal Checkout',
		slug: 'personal-1-year',
		title: 'FOR WINE ENTHUSIASTS',
		subscriptionType: 'PERSONAL SUBSCRIPTION',
		numericPrice: 129,
		data: [
			// '30 days free trial for new users of the site!',
			'Only $11 a month!',
			'Exclusive in-depth articles by global wine experts, offering more than just reviews.',
			'Access to over 450,000 tasting notes covering regions all over the world',
			'Wine vintage charts dating back to the 1970s',
		],
		isShow: true,
	},
	{
		planId: 'commercial-1-year',
		price: '$249',
		agreementName: 'Commercial Subscription Agreement',
		due: 'year',
		billed: 'BILLED ANNUALLY',
		description:
			'Get full access and updates to all our articles and tasting notes on the website and apps',
		button_text: 'SUBSCRIBE',
		label: 'Commercial Annual',
		slug: 'commercial-1-year',
		title: 'FOR WINE TRADE PROFESSIONALS',
		subscriptionType: 'COMMERCIAL SUBSCRIPTION',
		numericPrice: 249,
		data: [
			'All features from Personal subscription',
			'Priority booking and opportunity to exhibit at Matter of Taste events',
			'Right to purchase RP rating stickers to display on bottles',
			'Marketing collaterals & usage of our logos, tasting notes and wine scores',
			'Option to add seats to your subscription for company staff at a discounted price',
		],
		isShow: true,
	},
]

export const FEATURES = [
	{
		label:
			'Access to over 450,000 tasting notes and one of the largest wine tasting and reviewing databases online dating back to the 1900s from expert wine critics based around-the-world covering all major wine regions and much more.',
		left: true,
		right: true,
	},
	{
		label:
			'In-depth articles by wine experts globally covering wine reviews, reports, terroir, winemaking processes, vineyard information, latest wine trends, personalities and practices.',
		left: true,
		right: true,
	},
	{
		label:
			'Wine Vintage charts that date back to the 1970s, showing wine scores by region and year as well as recommended drinking dates.',
		left: true,
		right: true,
	},
	{
		label:
			'Cellar management tool to store and keep track of your favorite wines with tasting notes.',
		left: true,
		right: true,
	},
	{
		label:
			'Trade Directory of businesses on where to purchase fine wines from around the world.',
		left: false,
		right: true,
	},
	// {
	// 	label:
	// 		'Booking priority and discounts for Matter of Taste, a premier fine-wine tasting event held worldwide showcasing the worlds best producers, industry experts and wines rated 90 points and above.',
	// 	left: true,
	// 	right: true,
	// },
	// {
	// 	label:
	// 		'Opportunity to exhibit and participate in B2B tradeshows in Matter of Taste events',
	// 	left: true,
	// 	right: true,
	// },
	{
		label:
			'Right to reproduce our logos, marketing collaterals and tasting notes and wines scores that help you sell your wines in store or online',
		left: false,
		right: true,
	},
	{
		label:
			'Right to purchase and display RP rating stickers on your winery bottles',
		left: false,
		right: true,
	},
]

export const ALGOLIA_FACET_TITLES: AlgoliaFacetTitleTypes = {
	// authors: 'REVIEWER',
	// countries: 'COUNTRIES',
	// issues: 'ISSUES',
	// producers: 'PRODUCERS',
	// vintages: 'VINTAGE',
	// wine_appellation_countries: 'COUNTRY',
	// wine_appellation_locations: 'SUB-REGIONS',
	// wine_appellation_locales: 'LOCALES',
	// wine_appellation_regions: 'REGIONS',
	// wine_color_classes: 'COLOR',
	// wine_drynesses: 'SWEETNESS',
	// wine_groups: 'GROUPS',
	// wine_maturities: 'MATURITY',
	// wine_types: 'WINE TYPE',
	// wine_varieties: 'VARIETIES',
	// publications: 'PUBLICATION',
	// regions: 'REGIONS',

	tags: 'Tags',
	regions: 'Region',
	countries: 'Country',
	'author.name': 'Reviewer',
	issue_title: 'Issue',
	// price_low: 'Release Price',
	// rating_computed: 'Rating',
	vintage: 'Vintage',
	reviewers: 'Author',
	country: 'Country',
	region: 'Region',
	sub_region: 'Sub-region',
	appellation: 'Appellation',
	sub_appellation: 'Sub-appellation',
	producer: 'Producer',
	name: 'Name',
	varieties: 'Variety',
	maturity: 'Maturity',
	type: 'Wine Type',
	color_class: 'Color',
	dryness: 'Sweetness',
	certified: 'Certified',
	publication_date: 'Publication Date',
}

export const algoliaFacetTitles = (): AlgoliaFacetTitleTypes => {

	if(!IsUserAuthenticated()) {
		return ALGOLIA_FACET_TITLES
	} else {
		return {
			// TEMPORARY COMMENT PRICE RATE RANGE FILTER
			// price_low: 'Release Price',
			// rating_computed: 'Rating',
			...ALGOLIA_FACET_TITLES,
		}
	}
}

export const SEARCH_FILTER_TRANSLATIONS = [
	{
		key: 'authors',
		filter: 'author',
	},
	{
		key: 'issues',
		filter: 'issue',
	},
]

export const SOCIAL_MEDIA_LINKS = {
	FACEBOOK: {
		name: 'facebook',
		url: 'https://www.facebook.com/WineAdvocate',
		target: '_fb',
	},
	TWITTER: {
		name: 'twitter',
		url: 'https://twitter.com/wine_advocate',
		target: '_twitter',
	},
	LINKEDIN: {
		name: 'linkedin',
		url: 'https://www.linkedin.com/company/rpwa/mycompany/',
		target: '_linkedin',
	},
	INSTAGRAM: {
		name: 'instagram',
		url: 'https://www.instagram.com/wine_advocate',
		target: '_instagram',
	},
	YOUTUBE: {
		name: 'youtube',
		url: 'https://www.youtube.com/channel/UCrTZu6KcjwjTlMkQ28eFxAQ',
		target: '_youtube',
	},
}

export const WIDGET_CONFIG = {
	WINES: {
		MAX_PER_PAGE: 5,
	},
	PRODUCERS: {
		MAX_PER_PAGE: 5,
	},
}

export const BREADCRUMBS = [
	{
		path: '/',
		display: 'Home',
	},
	{
		path: '/about/editorial-team',
		display: 'Editorial Team',
	},
	{
		path: '/about/reviewers',
		display: 'Our Reviewers',
	},
	{
		path: '/about/the-rating-system',
		display: 'The Rating System',
	},
	{
		path: '/about/the-wine-advocate',
		display: 'The Wine Advocate',
	},
	{
		path: '/free-publications/wine-journal',
		display: 'Wine Journal',
	},
	{
		path: '/free-publications/wine-discoveries',
		display: 'Wine Discoveries',
	},
	{
		path: '/free-publications/sustainability-and-wine',
		display: 'Sustaintability and Wine',
	},
	{
		path: '/resources/wine-news',
		display: 'Wine News',
	},
	{
		path: '/resources/about-wines',
		display: 'Glossary of Terms',
	},
	{
		path: '/resources/marketing-materials',
		display: 'Resources',
	},
	{
		path: '/search/article',
		display: 'Search Articles',
	},
	{
		path: '/search/wine',
		display: 'Search Wines',
	},
	{
		path: '/trade-partners/preferred-retailers',
		display: 'Preferred Retailers',
	},
	{
		path: '/trade-partners/preferred-wine-schools',
		display: 'Preferred Wine Schools',
	},
	{
		path: '/trade-partners/trade-directory',
		display: 'Trade Directory',
	},
]

export const RATING_RANGES = [
	{
		high: 100,
		low: 96,
		color: '#986b00',
		description: 'Extraordinary',
	},
	{
		high: 95,
		low: 90,
		color: '#c9633a',
		description: 'Outstanding',
	},
	{
		high: 89,
		low: 80,
		color: '#bd1919',
		description: 'Above Average to Excellent',
	},
	{
		high: 79,
		low: 70,
		color: '#801220',
		description: 'Average',
	},
	{
		high: 69,
		low: 60,
		color: '#8f2168',
		description: 'Below Average',
	},
	{
		high: 59,
		low: 0,
		color: '#432652',
		description: 'Appalling',
	},
]

export const SUSTAINABILITY_PAGES = {
	BANNER:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/banners/wine-advocate-banner/sustainability/banner-certified.jpg',
	CERTIFIED_ORGANIC_ILLUSTRATION:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/sustainability/sustainability-certified.png',
	CERTIFIED_ICON: '/img/sustainability-pages/certified-icon.png',
	GREEN_EMBLEM_BACKGROUND:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/media/banners/wine-advocate-banner/sustainability/banner-eco-distinction.jpg',
	GREEN_EMBLEM_LOGO: '/img/sustainability-pages/geen-emblem-icon.png',
	WHAT_QUALIFIES_GREEN_EMBLEM_ILLUSTRATION:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/sustainability/what-qualifies-eco.jpg',
	HOW_REWARDS_PRESENTED_LUSTRATION:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/sustainability/sustainability-eco.jpg',
	BIODYNAMIC_ILLUSTRATION:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/sustainability/certified-biodynamic.png',
	ORGANIC_ILLUSTRATION:
		'https://robert-parker-the-wine-advocate.s3-us-west-2.amazonaws.com/assets/media/sustainability/certified-organic.png',
}

export const WINE_MATURITIES = [
	{
		label: 'C',
		description: 'Caution, may be too old',
	},
	{
		label: 'E',
		description: 'Early maturing and accessible',
	},
	{
		label: 'NV',
		description: 'Vintage not declared',
	},
	{
		label: 'I',
		description: 'Irregular, even among the best wines',
	},
	{
		label: 'NT',
		description: 'Not yet sufficiently tasted to rate',
	},
	{
		label: 'R',
		description: 'Ready to drink',
	},
	{
		label: 'T',
		description: 'Still tannic, youthful or slow to mature',
	},
]

export const WINE_SEARCHER_API = {
	DEFAULT_FORMAT: 'j',
	URL: '',
	BRIDGE_PATH: '/wine-prices',
}

export const FAQ_PAGE = {
	SHELF_TALKERS: {
		url: 'https://robert-parker-the-wine-advocate.s3.amazonaws.com/faq/ShelfTalkersTaskFlow.png',
	},
	TASTING_NOTE: {
		url: 'https://robert-parker-the-wine-advocate.s3.amazonaws.com/faq/print-individual-tasting-note.jpg'
	},
	MULTIPLE_TASTING_NOTE_ONE: {
		url: 'https://robert-parker-the-wine-advocate.s3.amazonaws.com/faq/print-multiple-tasting-note-1.jpg'
	},
	MULTIPLE_TASTING_NOTE_TWO: {
		url: 'https://robert-parker-the-wine-advocate.s3.amazonaws.com/faq/print-multiple-tasting-note-2.jpg'
	},
	MULTIPLE_TASTING_NOTE_THREE: {
		url: 'https://robert-parker-the-wine-advocate.s3.amazonaws.com/faq/print-multiple-tasting-note-3.jpg'
	}
}

export const PLAN_ANALYTICS = [
	{
		index: 0, 
		plan: 'wine-beginners',
		planId: 'personal-monthly',
		item_list_id: 'rpwa_personals_plans',
		item_list_name: 'RPWA personals plans',
		item_brand: 'Personal subscription',
		item_name: 'Monthly personal subscription',
		item_category: 'Personal',
		item_category2: 'Monthly',
		coupon: undefined,
		discount: undefined,
		price: 15,
		originalPrice: 15,
		recommended: false
	},
	{
		index: 1,
		plan: 'wine-enthusiasts',
		planId: 'personal-3-month',
		item_list_id: 'rpwa_personals_plans',
		item_list_name: 'RPWA personals plans',
		item_brand: 'Personal subscription',
		item_name: 'Quaterly personal subscription',
		item_category: 'Personal',
		item_category2: 'Quarterly',
		coupon: undefined,
		discount: undefined,
		price: 12.3,
		originalPrice: 37,
		recommended: false
	},
	{
		index: 2,
		plan: 'wine-sommeliers',
		planId: 'personal-1-year',
		item_list_id: 'rpwa_personals_plans',
		item_list_name: 'RPWA personals plans',
		item_brand: 'Personal subscription',
		item_name: 'Annual personal subscription',
		item_category: 'Personal',
		item_category2: 'Annual',
		coupon: undefined,
		discount: undefined,
		price: 11,
		originalPrice: 129,
		recommended: true
	},
	{
		index: 0,
		plan: 'commercial-basic',
		planId: 'commercial-1-year',
		item_list_id: 'rpwa_commercials_plans',
		item_list_name: 'RPWA commercials plans',
		item_brand: 'Commercial subscription',
		item_name: 'Commercial Basic',
		item_category: 'Commercial',
		item_category2: 'Commercial Basic',
		coupon: undefined,
		discount: undefined,
		price: 249,
		originalPrice: 249,
		recommended: false
	},
	{
		index: 1,
		plan: 'commercial-guide',
		planId: 'commercial-1-year-t2',
		item_list_id: 'rpwa_commercials_plans',
		item_list_name: 'RPWA commercials plans',
		item_brand: 'Commercial subscription',
		item_name: 'I Guide',
		item_category: 'Commercial',
		item_category2: 'I Guide',
		coupon: undefined,
		discount: undefined,
		price: 500,
		originalPrice: 500,
		recommended: true
	},
	{
		index: 2,
		plan: 'commercial-promote',
		planId: 'commercial-1-year-t3',
		item_list_id: 'rpwa_commercials_plans',
		item_list_name: 'RPWA commercials plans',
		item_brand: 'Commercial subscription',
		item_name: 'I Promote',
		item_category: 'Commercial',
		item_category2: 'I Promote',
		coupon: undefined,
		discount: undefined,
		price: 2000,
		originalPrice: 2000,
		recommended: false
	},
]

export const TASTING_NOTES_LIST = [
	{
		type: 'add-500',
		quantity: 500,
		cost: 800
	},
	{
		type: 'add-1000',
		quantity: 1000,
		cost: 1500
	},
	{
		type: 'add-2000',
		quantity: 2000,
		cost: 2400
	},
	{
		type: 'add-3000',
		quantity: 3000,
		cost: 3000
	},
]

export const USER_SECURITY_CREDENTIALS = {
	User1: {
		Username: 'efwsqxb',
		Password: 'HQjtM3|f:IRnACC'
	},
	User2: {
		Username: 'sqxb.benjo',
		Password: ',7q[oT6Ir18EVv'
	},
	User3: {
		Username: 'sqxb.tubgg',
		Password: 'ZIkXuu:4SeNlS3V'
	}
}
